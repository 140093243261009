import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./header";
import Footer from "./footer";

export default function Layout({ children }) {
  const { sanityHomePage } = useStaticQuery(graphql`
    query {
      sanityHomePage {
        title
        logo {
          asset {
            gatsbyImageData(fit: FILLMAX, formats: WEBP)
          }
        }
        copyright
      }
    }
  `);
  return (
    <>
      <Header logo={sanityHomePage?.logo} />
      <main className="px-5 lg:px-0">{children}</main>
      <Footer copyright={sanityHomePage?.copyright} />
    </>
  );
}
